export const Logo = ({ color = "hsl(0,0%,85%)" }) => {
  return (
    <svg
      width="359"
      height="34"
      viewBox="0 0 359 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.592 33.4881C21.568 33.4881 26.88 30.2881 26.88 24.5281C26.88 18.8321 21.696 16.3361 15.808 13.7761C10.368 11.4081 6.976 9.61612 6.976 5.96811C6.976 3.08811 9.28 1.10411 12.992 1.10411C17.856 1.10411 20.224 4.62411 20.8 9.61612H26.496C25.216 3.47211 20.032 0.0161133 13.248 0.0161133C6.464 0.0161133 1.664 3.21611 1.664 8.52811C1.664 14.0961 6.464 16.4001 11.968 18.8321C18.176 21.6481 21.44 23.1201 21.44 26.8321C21.44 30.0321 18.944 32.4001 14.656 32.4001C9.152 32.4001 6.144 28.1761 5.696 21.9681H0C0.896 28.8161 6.336 33.4881 14.592 33.4881Z"
        fill={color}
      />
      <path
        d="M29.2735 32.4001H45.3375V31.9521C41.1775 31.3121 40.6015 29.6481 40.6015 23.8241V0.400116L29.4655 1.29611V1.74411C33.6255 2.06411 34.0735 3.21611 34.0735 9.48812L34.0095 23.8241C34.0095 29.6481 33.4335 31.3121 29.2735 31.9521V32.4001Z"
        fill={color}
      />
      <path
        d="M46.7945 32.4001H62.4745V31.9521C58.6345 31.3121 58.0585 29.6481 58.0585 23.8241V11.0881C59.4025 6.48011 62.5385 2.83212 67.1465 2.83212C71.2425 2.83212 73.3545 5.26411 73.3545 11.1521V23.8241C73.3545 29.6481 72.8425 31.3121 69.0665 31.9521V32.4001H84.2985V31.9521C80.4585 31.3121 79.8825 29.6481 79.8825 23.8241V11.2801C79.8825 3.28012 76.2985 0.0161133 70.1545 0.0161133C64.0745 0.0161133 59.9785 3.92011 58.0585 8.78411C58.1225 3.28012 58.5065 1.74411 59.9785 1.10411H46.9225V1.55211C50.9545 2.19211 51.5305 3.40812 51.5305 9.68011V23.8241C51.5305 29.6481 50.9545 31.3121 46.7945 31.9521V32.4001Z"
        fill={color}
      />
      <path
        d="M103.125 33.4881C103.97 33.4881 169.505 33.4352 170.281 33.3315C177.333 32.3903 181.595 27.2675 183.209 19.6001H182.313C180.457 26.1921 176.681 30.2881 170.281 30.2881C169.416 30.2881 103.882 30.2093 103.125 30.0582C96.8301 28.8015 94.229 22.5473 94.229 15.1201C94.229 14.4801 94.229 13.9041 94.293 13.3281H182.825C182.569 5.77612 177.193 0.0161133 168.361 0.0161133C167.932 0.0161133 102.782 0.0313897 102.361 0.0616646C94.125 0.654137 87.637 6.99038 87.637 16.9761C87.637 27.0881 93.909 33.4881 103.125 33.4881ZM94.421 11.9201C95.1266 6.04046 97.9387 1.8353 102.361 1.19038C102.753 1.13331 166.084 1.10411 166.5 1.10411C171.427 1.10411 176.489 0.0161133 176.489 11.9201H94.421Z"
        fill={color}
      />
      <path
        d="M201.879 33.4881C208.855 33.4881 214.167 30.2881 214.167 24.5281C214.167 18.8321 208.983 16.3361 203.095 13.7761C197.655 11.4081 194.263 9.61612 194.263 5.96811C194.263 3.08811 196.567 1.10411 200.279 1.10411C205.143 1.10411 207.511 4.62411 208.087 9.61612H213.783C212.503 3.47211 207.319 0.0161133 200.535 0.0161133C193.751 0.0161133 188.951 3.21611 188.951 8.52811C188.951 14.0961 193.751 16.4001 199.255 18.8321C205.463 21.6481 208.727 23.1201 208.727 26.8321C208.727 30.0321 206.231 32.4001 201.943 32.4001C196.439 32.4001 193.431 28.1761 192.983 21.9681H187.287C188.183 28.8161 193.623 33.4881 201.879 33.4881Z"
        fill={color}
      />
      <path
        d="M231.291 33.0401C235.515 33.0401 239.547 30.1601 241.787 24.0801L241.211 23.5681C239.547 27.7281 237.051 29.7761 234.043 29.7761C231.035 29.7761 229.179 28.3041 229.179 22.7361V2.57611H239.739V1.10411H229.179H228.923H222.651H216.251V2.57611H222.651V22.8641C222.651 30.7361 226.619 33.0401 231.291 33.0401Z"
        fill={color}
      />
      <path
        d="M259.412 33.4881C267.924 33.4881 272.98 28.1121 274.772 19.6001H273.876C272.02 26.1921 268.244 30.2881 261.844 30.2881C253.78 30.2881 250.516 23.4401 250.516 15.1201C250.516 14.4801 250.516 13.9041 250.58 13.3281H274.388C274.132 5.77612 268.756 0.0161133 259.924 0.0161133C251.092 0.0161133 243.924 6.48011 243.924 16.9761C243.924 27.0881 250.196 33.4881 259.412 33.4881ZM250.708 11.9201C251.476 5.52011 254.74 1.10411 259.86 1.10411C265.236 1.10411 267.924 5.84011 268.052 11.9201H250.708Z"
        fill={color}
      />
      <path
        d="M278.596 32.4001H304.964V27.1521C301.7 29.5201 297.796 31.3121 292.292 31.3121H285.636L304.644 2.19211V1.10411H278.596V5.96811C282.18 3.79211 286.084 2.19211 290.436 2.19211H297.54L278.596 30.9281V32.4001Z"
        fill={color}
      />
      <path
        d="M306.998 32.4001H323.062V31.9521C318.902 31.3121 318.326 29.6481 318.326 23.8241V0.400116L307.19 1.29611V1.74411C311.35 2.06411 311.798 3.21611 311.798 9.48812L311.734 23.8241C311.734 29.6481 311.158 31.3121 306.998 31.9521V32.4001Z"
        fill={color}
      />
      <path
        d="M334.439 33.2321C341.223 33.2321 345.383 28.4321 346.855 22.0961V25.4881C346.855 30.7361 349.287 33.0401 352.679 33.0401C355.175 33.0401 356.903 32.0161 358.695 30.0961L358.631 29.2641C357.799 29.9681 357.159 30.3521 355.943 30.3521C354.215 30.3521 353.319 28.8801 353.319 25.9361V10.7041C353.319 2.76811 348.071 0.0161133 341.735 0.0161133C335.335 0.0161133 327.271 3.15211 326.951 11.2161H332.839C332.839 3.60011 337.383 1.16811 340.903 1.16811C344.743 1.16811 346.983 3.40812 346.919 7.56812C346.791 12.5601 343.911 13.4561 337.703 15.1841C329.767 17.3601 325.543 19.6001 325.543 25.1681C325.543 30.4801 329.319 33.2321 334.439 33.2321ZM331.815 23.8241C331.815 19.2801 334.759 17.5521 339.559 16.0161C343.783 14.7361 346.215 13.8401 346.919 11.8561V15.8881C346.919 24.1441 343.143 29.5841 337.191 29.5841C334.183 29.5841 331.815 27.7281 331.815 23.8241Z"
        fill={color}
      />
    </svg>
  );
};
